<template>
  <div>
    <HeroSection id="hero"></HeroSection>
    <IntroSection id="intro"></IntroSection>
    <ProductsSection id="products"></ProductsSection>
    <BusinessSection id="business"></BusinessSection>
    <CallToAction
      id="call-to-action"
      v-bind="calltoActionSection"
    ></CallToAction>
    <ProjectGallerySection id="project-gallery"></ProjectGallerySection>
    <!-- <RecomendationSection id="testimonials"></RecomendationSection> -->
    <BuildSomethingSection id="build-with-us"></BuildSomethingSection>
  </div>
</template>

<script lang="ts" setup>
import { calltoActionSection } from "~/lib/constants";
useHead({
  title: "CAT | Creative Advanced Technology",
  meta: [
    {
      name: "description",
      content: "CAT Services",
    },
  ],
});
</script>

<style></style>
